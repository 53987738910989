<template>
  <EditPages/>
</template>

<script>
import EditPages from '../components/EditPages.vue'

export default {
    components: {
        EditPages
    }
}
</script>

<style>

</style>