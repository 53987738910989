<template>
    <v-container>
        <v-col style="margin: 20px auto 0" :cols="breakpoints">
            <v-row class="mb-4">
                <h1>Seite bearbeiten</h1>
            </v-row>
                <v-text-field
                :loading = loading
                class="col-12"
                outlined
                disabled
                label="Dateiname"
                v-model="data.title"
                ></v-text-field>
                <v-text-field
                :loading = loading
                class="col-12"
                outlined
                label="Titel"
                v-model="data.siteTitle"
                ></v-text-field>
                <v-textarea
                :loading = loading
                class="col-12"
                outlined
                label="Content"
                v-model="data.content"
                ></v-textarea>

                <v-checkbox
                v-model="data.anmeldungAktiv"
                label="Lageranmeldung aktivieren"
                >
                </v-checkbox>

                <v-row class="my-4">
                    <h1>Hinweis Btn</h1>
                </v-row>

                <v-text-field
                :loading = loading
                class="col-12"
                label="Hinweis Banner"
                outlined
                v-model="data.hinweis"
                ></v-text-field>
                <v-text-field
                :loading = loading
                class="col-12"
                outlined
                label="Hinweis Banner Button"
                v-model="data.hinweisBtn"
                ></v-text-field>
                <v-text-field
                :loading = loading
                class="col-12"
                label="Email"
                outlined
                v-model="data.mailto"
                ></v-text-field>

                <div v-show="data.faq">
                    <h1>Lager FAQ's</h1>
                    <div class="my-5" v-for="fa in data.faq" :key="fa.index">
                    <v-text-field
                    :loading = loading
                    class="col-12"
                    label="Frage"
                    outlined
                    v-model="fa.frage"
                    ></v-text-field>
                    <v-textarea
                    :loading = loading
                    class="col-12"
                    label="Antwort"
                    outlined
                    v-model="fa.antwort"
                    ></v-textarea>
                    <v-btn
                    class="mb-5"
                    style="color: white"
                    color="red"
                    @click="removeIndex(fa)"
                    >Frage entfernen</v-btn>
                    <hr>
                    </div>
                    <v-btn @click="addFAQ()" >FAQ hinzufügen</v-btn>
                </div>
            <v-row class="mt-6">
                <v-btn
                large
                @click="SavePage()"
                color=primary
                >
                Speichern
                </v-btn>
                <v-btn
                to="/pages"
                class="ml-3"
                large
                >
                Abbrechen
                </v-btn>
            </v-row>
        </v-col>
    </v-container>
</template>

<script>
import axios from "axios"
import Vue from "vue"

export default {
    data: () => ({
        params: "Hello",
        fetchURL: "https://kjg-api.rezept-zettel.de/api/pages/",
        siteTitle: "",
        content: "",
        hinweis: "",
        hinweisBtn: "",
        anmeldungAktiv: false,
        mailto: "",
        data: [],
        faq: [],
    }), 
    computed: {
        /* eslint-disable */ 
      breakpoints () {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs': return 12
          case 'sm': return 12
          case 'md': return 6
          case 'lg': return 6
          case 'xl': return 6
        }
      },
    },
    methods: {  
        addFAQ () {
            this.data.faq.push({
                antwort: "",
                frage: "",
                active: false
            })
        },
        removeIndex(index) {
            this.faq.splice(this.faq.indexOf(index), 1);
        },
        getParams() {
            this.loading = true
            this.params = this.$route.params.id;
            this.fetchParamPage()
        },
        fetchParamPage: async function () {
            await axios
                .get(this.fetchURL + this.params)
                .then(response => response.data)
                .then(data => {
                    this.data = data
                    this.loading = false
                })
                .catch((err) => {
                    console.log(err)
                    this.loading = false
            })
        },
        SavePage () {
            this.loading = true
            axios
            .post(this.fetchURL, {
                "siteTitle": this.data.siteTitle,
                "title": this.data.title,
                "content": this.data.content,
                "hinweis": this.data.hinweis,
                "hinweisBtn": this.data.hinweisBtn,
                "anmeldungAktiv": this.data.anmeldungAktiv,
                "mailto": this.data.mailto,
                "faq": this.data.faq
                }
            )
            .then(response => {
                console.log(response)
                this.loading = false
                this.fetchParamPage()
                Vue.$toast.open({
                  message: 'Seite wurde gespeichert',
                  type: 'success',
              });
            })
            .catch((err) => {
                console.log(err)
                Vue.$toast.open({
                  message: 'Versuche es später erneut',
                  type: 'error',
              });
            })
        }
    },
    created() {
        this.getParams()
    }
}
</script>

<style>

</style>